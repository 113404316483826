import { html } from "lit";
import { ClientService } from "../client-service/client-service";
import { ModalLayover } from "../components/modal-layover";
import { LocalStorageService } from "../local-storage/local-storage-service";
import { ConsoleInterceptor } from "../console-logger/console-logger";


/** Handle themes for the header element */
const clientService = new ClientService();
const storageService = new LocalStorageService();

document.addEventListener('DOMContentLoaded', () => {
    const settingsEl = document.getElementById('href-app')!
    let longPressTimeout: ReturnType<typeof setTimeout>;

    const startLongPress = () => {
        longPressTimeout = setTimeout(showModal, 5_000);
    };

    const cancelLongPress = () => {
        clearTimeout(longPressTimeout);
    };

    // Mouse events
    settingsEl.addEventListener("mousedown", startLongPress);
    settingsEl.addEventListener("mouseup", cancelLongPress);
    settingsEl.addEventListener("mouseleave", cancelLongPress);

    // Touch events
    settingsEl.addEventListener("touchstart", startLongPress);
    settingsEl.addEventListener("touchend", cancelLongPress);
    settingsEl.addEventListener("touchcancel", cancelLongPress);
});

async function showModal(): Promise<void> {
    const clientService = new ClientService();

    const contents = {
        config: clientService.getConfig(),
        // TODO: this is a singleton; but hacky way to do it
        logs: new ConsoleInterceptor(console).getLogs()
    };

    const configJSON = JSON.stringify(contents, null, 4);
    const modal = new ModalLayover();
    modal.title = "Technical Specifications";
    modal.classList.add('left-content')
    modal.contentTemplate = html`
<pre style="width: 100%">
<code>
${configJSON}
</code>
</pre>
    `;
    modal.open = true;

    document.body.appendChild(modal);
}